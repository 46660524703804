<template>
  <div id="privacy-policy">
    <div class="body-2 d-flex flex-column">
      <h2>{{ $t("privacyPolicy") }}</h2>
      <h3>{{ $t("introduction") }}</h3>
      <p class="legalTxt">
        {{ $t("privacy1") }}
        <a href="https://www.beenotes.com/">https://www.beenotes.com/</a>
        {{ $t("privacy1-2") }}
        <a href="https://app.beenotes.com/"> https://app.beenotes.com/</a>
        {{ $t("privacy1-3") }}
        <a
          href="https://play.google.com/store/apps/details?id=com.inception.inspectionapp.full"
          >https://play.google.com/store/apps/details?id=com.inception.inspectionapp.full</a
        >
        {{ $t("privacy1-4") }}
        <a href=" https://www.apple.com/app-store/"> https://www.apple.com/app-store/</a>
        {{ $t("privacy1-5") }}
      </p>
      <h3>{{ $t("personalData") }}</h3>
      <p class="legalTxt">
        {{ $t("privacy2") }}
      </p>
      <h3>{{ $t("purpose") }}</h3>
      <p class="legalTxt">
        {{ $t("privacy3") }}
      </p>
      <h3>{{ $t("method") }}</h3>
      <p class="legalTxt">
        {{ $t("privacy4") }}
      </p>
      <h3>{{ $t("rights") }}</h3>
      <p class="legalTxt">
        {{ $t("privacy5") }} <a href="mailto: info@beenotes.com">info@beenotes.com</a>
      </p>
      <h3>{{ $t("useOfCookies") }}</h3>
      <p class="legalTxt">
        {{ $t("privacy6") }}
        <a @click="$router.push({path: 'cookie_policy'})">{{ $t("cookiePol") }}</a>
        {{ $t("privacy6-2") }}
      </p>
      <h3>{{ $t("law") }}</h3>
      <p class="legalTxt">
        {{ $t("privacy7") }}
      </p>
      <h3 class="text-uppercase">{{ $t("lang") }}</h3>
      <p class="legalTxt">
        {{ $t("privacy8") }}
      </p>
      <h3>{{ $t("contact") }}</h3>
      <p class="legalTxt">
        {{ $t("privacy9") }} <a href="mailto: info@beenotes.com">info@beenotes.com</a>
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PrivacyPolicy'
  };
</script>

<style lang="css" scoped>
  #privacy-policy {
    margin-top: -70px;
    padding: 20px;
  }

  .legalTxt {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #4E4B66;
  }

  .legalTxt,
  h2,
  h3 {
    padding: 20px 8% 0px 8%;
  }

  h2,
  h3 {
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
    font-weight: 600;
    color: #4E4B66;
  }

  @media screen and (max-width: 600px) {
    #privacy-policy {
      margin-top: -128px;
    }

    .legalTxt,
    h2,
    h3 {
      padding: 10px 20px 0;
    }
  }
</style>
